import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {json_news, json_press} from "../../ducks/state";


class NewsListItem extends Component {

    render() {


      const { news, flux } = this.props
      let date = new Date(news.date);

      var months = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"]
      let date_y = date.getFullYear();
      date = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
      if(flux === 'presse' || flux === 'collaborations'){
        date = date_y;
      }

        return (
            <Link
              to={`/${flux}/${news.slug}/`}
              className="news_list_item">

                <div className="date">
                  {date}
                </div>
                <div className="news_list_item_title">
                  {news.title}
                </div>
              <div className="news_list_item_bg"/>
            </Link>
        )
    }
}

export default NewsListItem