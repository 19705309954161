import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Logo from '../Logo/Logo'
import {TweenMax} from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import {json_news, json_press, json_col, json_content, json_works} from '../../ducks/state'
import _ from "lodash";

import WorksCard from "../../pages/Work/Work";



const C = CSSPlugin;

class Menu extends Component {






  componentDidMount() {


      TweenMax.set('.menu_hover',  {'pointer-events': 'auto'});
      TweenMax.set('.menu_logo_4 .logo .logo_lastname',  {x: '-10vw'});
      TweenMax.set('.menu_logo_1 .logo .logo_baseline_top',  {y: '100%'});
      TweenMax.set('.menu_logo_2 .logo .logo_baseline_top',  {y: '-100%'});
      TweenMax.set('.menu_logo_3 .logo .logo_firstname',  {x: '20vw'});
      TweenMax.set('.menu_items',  {autoAlpha: 0, y: 5});
      TweenMax.set('.menu_btn_content_burger',  {x: 0});
      TweenMax.set('.menu_btn_content span',  {autoAlpha: 1});
      TweenMax.set('.menu_grid_bloc.menu_baseline div',  {autoAlpha: 0, y: -5});
      TweenMax.set('.menu_btn_content_burger',  {x: 30});
      TweenMax.set('.menu_btn_content span', {autoAlpha: 0});
      TweenMax.set('.menu_logo',  {autoAlpha: 0});
      TweenMax.set('.menu_lines_vert',  {height: 0});
      TweenMax.set('.menu_lines_hori',  {width: 0});
      TweenMax.set('.menu_mobile',  {y: '100%'});

  }



  hideMenu() {
    TweenMax.set('.menu_hover',  {'pointer-events': 'none'});
    TweenMax.to('.menu_logo_4 .logo .logo_lastname', 0.25, {x: '-10vw', ease: "Power4.easeIn"});
    TweenMax.to('.menu_logo_1 .logo .logo_baseline_top', 0.3, {y: '100%', ease: "Power4.easeIn"});
    TweenMax.to('.menu_logo_2 .logo .logo_baseline_top', 0.3, {y: '-100%', ease: "Power4.easeIn"});
    TweenMax.to('.menu_logo_3 .logo .logo_firstname', 0.35, {x: '20vw', ease: "Power4.easeIn"});
    TweenMax.to('.menu_items', 0.2, {autoAlpha: 0, y: 5});
    TweenMax.to('.menu_btn_content_burger', 0.6, {x: 0, ease: "Power4.easeOut"});
    TweenMax.to('.menu_btn_content span', 0.5, {autoAlpha: 1, ease: "Power4.easeOut", delay: 0.2});
    TweenMax.to('.menu_grid_bloc.menu_baseline div', 0.4, {autoAlpha: 0, y: -5});
    TweenMax.to('.menu_logo', 0.4, {autoAlpha: 1});
    TweenMax.to('.menu_mobile',0.5,  {y: '0%'});
    TweenMax.set('.menu_logo_5 .logo .logo_content',  {x: '-25%', y: '-18%'});
    this.display_lines(0);


  }


  showMenu() {
    TweenMax.set('.menu_hover',  {'pointer-events': 'auto'});
    TweenMax.to('.menu_logo_4 .logo .logo_lastname', 0.5, {x: '0vw', ease: "Power1.easeOut"});
    TweenMax.to('.menu_logo_1 .logo .logo_baseline_top', 0.5, {y: '0%', ease: "Power2.easeInOut"});
    TweenMax.to('.menu_logo_2 .logo .logo_baseline_top', 0.5, {y: '0%', ease: "Power2.easeInOut"});
    TweenMax.to('.menu_logo_3 .logo .logo_firstname', 0.5, {x: '0vw', ease: "Power2.easeInOut"});
    TweenMax.staggerTo('.menu_items', 0.4, {autoAlpha: 1, y: 0, delay: 0.3},0.05);
    TweenMax.to('.menu_logo_5 .logo .logo_content', 0.5, {x: '-25%', y: '-18%', ease: "Power1.easeInOut"});
    TweenMax.to('.menu_btn_content_burger', 0.2, {x: 30, ease: "Power4.easeIn"});
    TweenMax.to('.menu_btn_content span', 0.3, {autoAlpha: 0});
    TweenMax.to('.menu_grid_bloc.menu_baseline div', 0.6, {autoAlpha: 1, y: 0,delay:0.3 , ease: "Power1.easeOut"});
    TweenMax.to('.menu_logo', 0.4, {autoAlpha: 1});
    TweenMax.to('.menu_mobile',0.2,  {y: '100%'});
  }
  showMenuIntro(delay) {
    TweenMax.set('.menu_hover',  {'pointer-events': 'auto'});
    TweenMax.to('.menu_logo_4 .logo .logo_lastname', 0.9, {x: '0vw', ease: "Power1.easeOut", delay:delay, force3D:true});
    TweenMax.to('.menu_logo_1 .logo .logo_baseline_top', 0.8, {y: '0%', delay:delay+0.1, ease: "Power2.easeInOut", force3D:true});
    TweenMax.to('.menu_logo_2 .logo .logo_baseline_top', 0.6, {y: '0%', delay:delay  +0.2,ease: "Power2.easeInOut", force3D:true});
    TweenMax.to('.menu_logo_3 .logo .logo_firstname', 0.6, {x: '0vw', ease: "Power2.easeInOut", delay:delay, force3D:true});
    TweenMax.staggerTo('.menu_items', 0.4, {autoAlpha: 1, y: 0, delay: delay + 0.6, force3D:true},0.05);
    TweenMax.to('.menu_logo_5 .logo .logo_content', 0.9, {x: '-25%', y: '-18%', ease: "Power1.easeInOut", delay:delay, force3D:true});
    TweenMax.to('.menu_btn_content_burger', 0.5, {x: 30, ease: "Power4.easeIn", delay:delay, force3D:true});
    TweenMax.to('.menu_btn_content span', 0.6, {autoAlpha: 0, delay:delay, force3D:true});
    TweenMax.to('.menu_grid_bloc.menu_baseline div', 0.5, {autoAlpha: 1, y: 0,delay:delay+0.5 , ease: "Power1.easeOut", force3D:true});
    TweenMax.to('.menu_logo', 0.9, {autoAlpha: 1, delay:delay, force3D:true});
  }

  moveLogo(step_x, step_y) {
    TweenMax.to('.menu_logo_5 .logo .logo_content', 1, {x: step_x, y: step_y, ease: "Power1.easeInOut"});
  }

  display_lines(delay){
    TweenMax.staggerTo('.menu_lines_vert', 1, {height:'100vh', ease: "Power1.easeOut", delay:delay},0.2);
    TweenMax.staggerTo('.menu_lines_hori', 1, {width:'100vw', ease: "Power1.easeOut" ,delay:0.3+delay},0.2);
    TweenMax.set('.menu_btn_bg',  {autoAlpha:1,delay:delay+1});

  }




  render() {

    var months = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"]


    const news = _.first(json_news.news);
    let date_news = new Date(news.date);
    date_news = date_news.getDate() + " " + months[date_news.getMonth()] + " " + date_news.getFullYear();

    const press = _.first(json_press.press);
    let date_press = new Date(press.date);
    date_press = date_press.getFullYear();


    const col = _.first(json_col.col);
    let date_col = new Date(col.date);
    date_col = date_col.getFullYear();






    return (
      <div className="menu">
        <div className="menu_grid">
          <div className="menu_grid_bloc menu_logo">
            <Link to="/" >
              <img src="/logoNThepe.png"/>
            </Link>
          </div>
          <Link to="/"  className="menu_btn">
            <div className="menu_btn_bg"/>
            <div className="menu_grid_bloc ">
              <div className="menu_btn_content">
                <img src="/logoNThepe.png"/>
                <span>
                Menu
              </span>
                <div className="menu_btn_content_burger">
                  <svg viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="20" height="2" rx="1" fill="#000000"/>
                    <rect y="5" width="18" height="2" rx="1" fill="#000000"/>
                    <rect y="10" width="13" height="2" rx="1" fill="#000000"/>
                  </svg>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/lagence" >
            <div className="menu_grid_bloc menu_logo_main menu_hover">
              <div className="menu_hover_bg"/>
                <div className="menu_items">
                  <h2>L'agence</h2>
                  { json_content['content']['home']['agence_texte'] }
                </div>
            </div>
          </Link>
          <div className="menu_grid_bloc ">
          </div>
          <div className="menu_grid_bloc menu_baseline">
            <div dangerouslySetInnerHTML={{__html:json_content['content']['home']['texte'].replace(/\n/g, "<br />") }}>

            </div>
          </div>
          <div className="menu_grid_bloc"/>
          <div className="menu_grid_bloc"/>
          <Link to="/presse">
          <div className="menu_grid_bloc menu_hover">
            <div className="menu_hover_bg"/>
            <div className="menu_items ">
              <h2>Revue de<br></br>presse</h2>
              <div className="date">
                { date_press }
              </div>
              <span>{ press.title }</span>
            </div>
          </div>
          </Link>
          <div className="menu_grid_bloc grid_overflow_visible"/>
          <div className="menu_grid_bloc menu_logo_1">
            <div className="logo">
              <div className="logo_baseline_top">
                Architecture
              </div>
            </div>
          </div>
          <Link to="/realisations" >
          <div className="menu_grid_bloc menu_hover">
            <div className="menu_hover_bg"/>
                <div className="menu_items">
                  <h2>Réalisations</h2>
                  { json_content['content']['home']['realisations_texte'] }
                </div>
          </div>
          </Link>
          <div className="menu_grid_bloc menu_logo_3">
            <div className="logo">
              <div className="logo_firstname">
                Françoise
              </div>
            </div>
          </div>
          <Link to="/actualites">
          <div className="menu_grid_bloc menu_hover">
            <div className="menu_hover_bg"/>
            <div className="menu_items">
              <h2>Actualités</h2>
              <div className="date">
                { date_news }
              </div>
              <span>{ news.title }</span>
            </div>
          </div>
          </Link>
          <div className="menu_grid_bloc"/>
          <div className="menu_grid_bloc menu_logo_2">
            <div className="logo">
              <div className="logo_baseline_top">
                Architecture
              </div>
            </div>
          </div>
          <Link to="/collaborations">
            <div className="menu_grid_bloc menu_hover">
              <div className="menu_hover_bg"/>
              <div className="menu_items">
                <h2>Collaborations</h2>
                <div className="date">
                  { date_col }
                </div>
                <span>{ col.title }</span>
              </div>
            </div>
          </Link>

          <div className="menu_grid_bloc"/>
          <div className="menu_grid_bloc"/>
          <div className="menu_grid_bloc"/>
          <Link to="/contact">
          <div className="menu_grid_bloc menu_hover">
            <div className="menu_hover_bg"/>
            <div className="menu_items">
                <h2>Contact</h2>
              <h5>Mail</h5>
              fnt@fnthepe-paris.com<br/><br/>
              <h5>Téléphone</h5>
              +33 1 40 37 88 79
            </div>
          </div>
          </Link>
        </div>
        <div className="menu_logo_4">
          <div className="logo">
            <div className="logo_lastname">
              N’thépé
            </div>
          </div>
        </div>
        <div className="menu_logo_5">
          <Logo/>
        </div>
        <div className="menu_lines">
          <div className="menu_lines_vert_1 menu_lines_vert" />
          <div className="menu_lines_vert_2 menu_lines_vert"/>
          <div className="menu_lines_vert_3 menu_lines_vert"/>
          <div className="menu_lines_vert_4 menu_lines_vert"/>
          <div className="menu_lines_hori_1 menu_lines_hori"/>
          <div className="menu_lines_hori_2 menu_lines_hori"/>
          <div className="menu_lines_hori_3 menu_lines_hori"/>

        </div>
      </div>
    )
  }
}

export default Menu
