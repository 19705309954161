import React, { Component } from 'react'
import WorksCard from './WorksCard'
import Menu from '../../components/Menu/Menu'
import Intro from "../../components/Intro/Intro";
import { json_works} from '../../ducks/state'
import {TweenMax} from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import {Link} from "react-router-dom";
const C = CSSPlugin;




class Works extends Component {

  componentDidMount()
  {

    const delay = 0.2

    Intro.prototype.disable_intro();
    Menu.prototype.moveLogo(0,0);
    Menu.prototype.hideMenu();

    TweenMax.set('.works_card_bg', {autoAlpha:0, scale:1,y:-5});
    TweenMax.set('.work_second  .works_card_bg', {autoAlpha:0, scale:1,y:5});
    TweenMax.set('.work_third  .works_card_bg', {autoAlpha:0, scale:1,y:-5});
    TweenMax.set('.work_fourth  .works_card_bg', {autoAlpha:0, scale:1,y:5});
    TweenMax.set('.works_card_content_title', {opacity:0, x:10});
    TweenMax.set('.works_card_content_location', {opacity:0, y:5});
    TweenMax.set('.work_first .works_card_bg_black', {height:'0%', width:"100%",top:0, bottom:'auto'});
    TweenMax.set('.work_second .works_card_bg_black', {height:'0%', width:"100%",bottom:0, top:'auto'});
    TweenMax.set('.work_third .works_card_bg_black', {height:'0%', width:"100%",top:0, bottom:'auto'});
    TweenMax.set('.work_fourth .works_card_bg_black', {height:'0%', width:"100%",bottom:0, top:'auto'});

    TweenMax.staggerTo('.work_first .works_card_bg_black',0.3,{height: '101%',top:-2, bottom:'auto', ease: "Power1.easeOut",delay:delay + 0},0.1);
    TweenMax.staggerTo('.work_first .works_card_bg',0.2, {autoAlpha:1,delay:delay },0.1);
    TweenMax.staggerTo('.work_first .works_card_bg',0.7, {y:0,delay:delay,ease: "Power1.easeOut"},0.1);
    TweenMax.staggerTo('.work_first .works_card_bg_black',0.3,{height: '0%',bottom:-2, top:'auto', ease: "Power1.easeInOut",delay:delay +0.3},0.1);

    TweenMax.staggerTo('.work_second .works_card_bg_black',0.3,{height: '101%',bottom:-2, top:'auto', ease: "Power1.easeOut",delay:delay +0},0.1);
    TweenMax.staggerTo('.work_second .works_card_bg',0.2, {autoAlpha:1,delay:delay },0.1);
    TweenMax.staggerTo('.work_second .works_card_bg',0.7, {y:0,delay:delay , ease: "Power1.easeOut"},0.1);
    TweenMax.staggerTo('.work_second .works_card_bg_black',0.3,{height: '0%',top:-2, bottom:'auto', ease: "Power1.easeInOut",delay:delay +0.3},0.1);

    TweenMax.staggerTo('.work_third .works_card_bg_black',0.3,{height: '101%',top:-2, bottom:'auto', ease: "Power1.easeOut",delay:delay + 0},0.1);
    TweenMax.staggerTo('.work_third .works_card_bg',0.2, {autoAlpha:1,delay:delay },0.1);
    TweenMax.staggerTo('.work_third .works_card_bg',0.7, {y:0,delay:delay ,ease: "Power1.easeOut"},0.1);
    TweenMax.staggerTo('.work_third .works_card_bg_black',0.3,{height: '0%',bottom:-2, top:'auto', ease: "Power1.easeInOut",delay:delay +0.3},0.1);

    TweenMax.staggerTo('.work_fourth .works_card_bg_black',0.3,{height: '101%',bottom:-2, top:'auto', ease: "Power1.easeOut",delay:delay +0},0.1);
    TweenMax.staggerTo('.work_fourth .works_card_bg',0.2, {autoAlpha:1,delay:delay },0.1);
    TweenMax.staggerTo('.work_fourth .works_card_bg',0.7, {y:0,delay:delay , ease: "Power1.easeOut"},0.1);
    TweenMax.staggerTo('.work_fourth .works_card_bg_black',0.3,{height: '0%',top:-2, bottom:'auto', ease: "Power1.easeInOut",delay:delay +0.3},0.1);


    var cells = document.querySelectorAll(".works_card");

    for (var i = 0; i < cells.length; i++) {
      cells[i].addEventListener("mouseenter", function() {
        TweenMax.killTweensOf(this.querySelector('.works_card_bg_black'));
        TweenMax.set(this.querySelector('.works_card_bg_black'), { backgroundColor:"#000000"});
        TweenMax.to(this.querySelector('.works_card_bg_black'),0, {height:'100%',bottom:0, top:'auto', ease: "Power1.easeOut"});
        TweenMax.to(this.querySelector('.works_card_content_title'),0.4, {y:0,x:0,opacity:1, ease: "Power1.easeOut"});
        TweenMax.to(this.querySelector('.works_card_content_location'),0.3, {y:0,x:0,opacity:1, ease: "Power1.easeOut", delay:0.05});
      });
      cells[i].addEventListener("mouseleave", function() {
        TweenMax.killTweensOf(this.querySelector('.works_card_content_location'));
        TweenMax.killTweensOf(this.querySelector('.works_card_content_title'));
        TweenMax.to(this.querySelector('.works_card_bg_black'),0, {height:'0%',top:0, bottom:'auto', ease: "Power1.easeOut"});
        TweenMax.to(this.querySelector('.works_card_content_title'),0, {y:0,x:10,opacity:0, ease: "Power1.easeOut"});
        TweenMax.to(this.querySelector('.works_card_content_location'),0, {y:5,x:0,opacity:0, ease: "Power1.easeOut"});
      });
    }


  }
  componentWillUnmount() {
    console.log('fesf');
  }



  render() {

      const list_works_item = json_works.works.map((work, i) => (
        <WorksCard
          key={work.id}
          work={work}
          number={i}
        />
      ))

    return (
        <div className="works">
          <div className="works_blank"></div>
            { list_works_item }
        </div>
    )
  }
}

export default Works
