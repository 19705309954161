import React, { Component } from 'react'
import {Link} from 'react-router-dom'


class Menu_mobile extends Component {
    render() {
        return (
        <Link to="/" className=" menu_mobile">
            <span>
                Menu
                  </span>
            <div className="menu_mobile_burger">
                <svg viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="20" height="2" rx="1" fill="#FFFFFF"/>
                    <rect y="5" width="18" height="2" rx="1" fill="#FFFFFF"/>
                    <rect y="10" width="13" height="2" rx="1" fill="#FFFFFF"/>
                </svg>
            </div>
        </Link>
        )
    }
}
export default Menu_mobile