export const TOGGLE_IS_LOADED = 'data/TOGGLE_IS_LOADED'

const initialState = {
  is_loaded: false,
}

/**
 * @param state
 * @param action
 * @returns {{is_loaded: boolean, work_cases: Array}}
 */
export default function reducer(state = initialState, action = {}) {
  console.log(action)
  switch (action.type) {
    case TOGGLE_IS_LOADED:
      console.log(action)
      return { ...state, is_loaded: !state.is_loaded }
    default:
      return state
  }
}

export const toggleIsLoaded = () => ({
    type: TOGGLE_IS_LOADED
})

/**
 * @param state
 * @returns {boolean}
 */
export function getIsLoaded(state) {
  return state.ui.is_loaded
}

