import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import App from './App'
import { createBrowserHistory } from 'history'
import { ConnectedRouter } from 'connected-react-router'
import {json_works, json_news, json_col, json_press, json_content} from "./ducks/state";

// SSR requirement
const state = window.__STATE__ || {}
delete window.__STATE__

const history = createBrowserHistory()
const store = configureStore(history, state)
const rootElement = document.getElementById('root')


fetch(process.env.REACT_APP_API_URL)
  .then(res => res.json())
  .then(
    (result) => {
      json_works['works'] = result['work'];
      json_news['news'] = result['news'];
      json_col['col'] = result['col'];
      json_press['press'] = result['press'];
      json_content['content'] = result['content'];
      ReactDOM.render(
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <App/>
          </ConnectedRouter>
        </Provider>,
        rootElement
      )
    },
    (error) => {

    }
  )
