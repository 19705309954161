import React, {Component} from 'react'
import WorksCard from "../Works/WorksCard";
import Menu from '../../components/Menu/Menu'
import Intro from '../../components/Intro/Intro'
import {TweenMax} from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import _ from "lodash";
import {json_works} from "../../ducks/state";
import WorkSwiper  from "./WorkSwiper";
import "react-id-swiper/src/styles/scss/swiper.scss";
import {Link} from "react-router-dom";
import TitleAndMetaTags from 'components/TitleAndMetaTags'
import striptags from 'striptags'


const C = CSSPlugin;
const S = ScrollToPlugin;
class Work extends Component {


  handleScroll = e => {
    let element = e.target;
    let new_y = (element.scrollTop/3) * -1;
    TweenMax.set('.work_img',{y:new_y});
  }

  showPage(){

    TweenMax.set('.work_content',{scrollTo﻿:{y:0} });
    TweenMax.set('.work_title_content div',{opacity:0, y:10 });
    TweenMax.set('.work_title_content span',{opacity:0, x:10 });
    TweenMax.set('.work_img_img',{opacity:0 });
    TweenMax.set('.work_title_bg',{height: '0%'});
    TweenMax.set('.work_back_bg',{height: '0%'});
    TweenMax.set('.work_back span',{opacity: 0});

    TweenMax.to('.work_img_bg',0.4,{height: '100%',top:'auto', bottom:0, ease: "Power1.easeOut"});
    TweenMax.set('.work_img_img',{opacity:1,delay:0.4,y:20 });
    TweenMax.to('.work_img_bg',0.4,{height: '0%', top:0, bottom:'auto', ease: "Power1.easeInOut", delay:0.5});
    TweenMax.to('.work_img_img',0.7,{y:0,  ease: "Power1.easeInOut", delay:0.4});
    TweenMax.to('.work_title_bg',0.6,{height: '100%', ease: "Power3.easeOut", delay:0.7});
    TweenMax.set('.work_img_img',{opacity:1,delay:0.4,y:10 });
    TweenMax.to('.work_img_img',0.7,{y:0,  ease: "Power1.easeInOut", delay:0.4});
    TweenMax.to('.work_title_content div',0.5,{opacity:1, y:0, delay:1 });
    TweenMax.to('.work_title_content span',0.5,{opacity:1, x:0, delay:1.1 });
    TweenMax.to('.work_back span',0.5,{opacity:1, delay:0.7 });
    TweenMax.to('.work_back_bg',0.3,{height: '100%', ease: "Power3.easeOut", delay:0.5});


  }


  componentDidMount() {

    Intro.prototype.disable_intro();

    Menu.prototype.hideMenu();
    Menu.prototype.moveLogo('-10vw','-2vw');

    this.showPage()

  }


  render() {


    let work_more_array = [];
    let more_work = [];
    this.showPage()
    const { match } = this.props;
    const slug = match.params.slug;
    const work = _.find(json_works.works, ['slug', slug])
    const work_cat = work.categorie;

    let work_more = _.filter(json_works.works, ['categorie', work_cat]);
    work_more = _.filter(work_more, (v) => _.indexOf([work.id], v.id) === -1 );
    if(work_more.length != 0) {
      work_more_array.push(work_more[Math.floor(Math.random() * work_more.length)]);
    }
    work_more = _.filter(work_more, (v) => _.indexOf([work_more_array[0].id], v.id) === -1 );
    if(work_more.length != 0) {

      work_more_array.push(work_more[Math.floor(Math.random() * work_more.length)]);
    }
    work_more = _.filter(work_more, (v) => _.indexOf([work_more_array[1].id], v.id) === -1 );
    if(work_more.length != 0)
    {
      work_more_array.push(work_more[Math.floor(Math.random() * work_more.length)]);
    }

    console.log(work_more_array);
    if(work_more_array[0] != undefined ) {
      more_work = work_more_array.map((work, i) => (
        <WorksCard
          key={work.id}
          work={work}
          number={i}
        />
      ))
    }
    var y=document.createElement('span');
    y.innerHTML=striptags(work.content);


    return (
      <div className="work" >
        <TitleAndMetaTags
          title={work.title + " - Françoise N'THEPE"}
          description={y.innerHTML}
          image={work.image}
        />
        <Link to="/realisations"   className="work_back" >
          <div className="work_back_bg" />
          <div>
            <img src="/logoNThepe.png"/>
            <span>
              Retour à la<br />
              liste des<br />
              projets
            </span>
          </div>
        </Link>
        <div className="work_img">
          <div className="work_img_img"
               style={{
                 backgroundImage: `url(${work.image})`
               }}
          />
          <div className="work_img_bg"/>
        </div>
        <div className="work_content" id="work_content" onScroll={this.handleScroll}>
          <div className="work_title">
            <div className="work_title_bg"/>
            <div className="work_title_content" >
              <span>{work.categorie}</span>
              <div>{work.title}</div>
            </div>
          </div>
          <div className="work_detail">
            <div className="work_detail_l">
              <span>Lieu</span>
              {work.place}
              <br/><br/>
              <span>Année</span>
              {work.year}
            </div>
            <div className="work_detail_l">
              <span>Client</span>
              {work.client}
              <br/><br/>
              <span>surface</span>
              {work.surface}
            </div>
            <div className="work_detail_r" dangerouslySetInnerHTML={{__html:work.content}}/>
          </div>
          <WorkSwiper
            gallery={work.images}
          />
          <div className="work_more">
            <div className="work_more_title">
              <span>
                Projets<br/>Similaires<div>( {work.categorie} )</div>
              </span>
            </div>
            <div className="work_more_list">
              {more_work}
            </div>
            <div className="work_more_r"/>
          </div>
        </div>
      </div>
    )
  }
}


export default Work
