import React, { Component } from 'react'
import {json_works} from "../../ducks/state";


class Preload extends Component {
    render() {

      let images = json_works.works.map((work, i) => (
        <img
          src={work.image}
          key={i}
        />
      ))

        return (
            <div className="preload">
              {images}
            </div>
        )
    }
}
export default Preload