import React, { Component } from 'react'

import NewsList from './NewsList'
import NewsPage from './NewsPage'
import Menu from '../../components/Menu/Menu'
import Intro from '../../components/Intro/Intro'
import { json_news } from '../../ducks/state'
import { TweenMax } from "gsap/all";
import _ from 'lodash';

class News extends Component {


  componentDidMount(){
    Intro.prototype.disable_intro();
    Menu.prototype.moveLogo('5vw','-15vw');
    Menu.prototype.hideMenu();


    TweenMax.set('.news_l_bg', {width: 0})
    TweenMax.set('.news_r_bg', {width: '100%',autoAlpha: 0});
    TweenMax.set('.title_bg', {height: 0})
    TweenMax.set('.title_content span', { y:50})
    TweenMax.set('.news_page', {autoAlpha: 1,delay:0.2});
    TweenMax.to('.news_l_bg',0.3, {width: '100%', ease: "Power1.easeOut"});
    TweenMax.to('.news_r_bg',0.1, {width: '100%',autoAlpha: 1, ease: "Power1.easeOut", delay:0.5});
    TweenMax.to('.title_bg',0.4, {height: '100%', ease: "Power2.easeInOut", delay:0.2});
    TweenMax.to('.title_content span',0.5, {y: 0, ease: "Power2.easeOut", delay:0.4});

  }



  render() {
      const { match } = this.props;
      let slug = ''
      if(!match.params.slug){
        slug = _.first(json_news.news).slug
      }else{
        slug = match.params.slug
      }
      return (
        <div className="news">
          <div className="news_l">
            <div className="news_l_bg"  />
            <div className="title">
              <div className="title_bg"  />
              <div className="title_content" ><span>Actualités</span></div>
            </div>
            <NewsList
              flux="actualites"
            />
          </div>
          <div className="news_r">
            <div className="news_r_bg"  />
            <NewsPage
              slug={slug}
              flux="actualites"
            />
          </div>
        </div>
      )
  }
}

export default News

