import {
  applyMiddleware,
  createStore,
  combineReducers,
  compose
} from 'redux'
import thunkMiddleware from 'redux-thunk'
import {
  connectRouter,
  routerMiddleware
} from 'connected-react-router'
import dataReducer from './ducks/data'
import uiReducer from './ducks/ui'

/**
 * Combine reducers
 * @param reducers
 * @param asyncReducers
 * @returns {Reducer<any> | Reducer<any, AnyAction>}
 */
const createRootReducer = (reducers, asyncReducers) => {
  return combineReducers({
    ...reducers,
    ...asyncReducers
  })
}
// redux devtools extension
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

/**
 * Return the configured store
 * @param history
 * @param preloadedState
 * @returns {any}
 */
export default function configureStore(history, preloadedState) {
  // Reducers
  const reducers = {
    router: connectRouter(history),
    data: dataReducer,
    ui: uiReducer,
  }

  // Middlewares
  const middlewares = [thunkMiddleware, routerMiddleware(history)]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  // Enhancers
  const enhancers = [middlewareEnhancer]
  const composedEnhancers = composeEnhancers(...enhancers)

  const store = createStore(
    createRootReducer(reducers),
    preloadedState,
    composedEnhancers
  )

  return store
}
