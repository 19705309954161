import React, { Component } from 'react'


class Logo extends Component {
    render() {
        return (
            <div className="logo">
                <div className="logo_content">
                    <div className="logo_firstname">
                        <span>Françoise</span>
                    </div>
                    <div className="logo_lastname">
                        <span>N’thépé</span>
                    </div>
                    <div className="logo_baseline_top">
                        <span>Architecture</span>
                    </div>
                    <div className="logo_baseline_bottom">
                        <span>& Design.paris</span>
                    </div>
                </div>
            </div>
        )
    }
}
export default Logo