import React, { Component } from 'react'

import NewsList from '../News/NewsList'
import NewsPage from '../News/NewsPage'
import _ from "lodash";
import { json_press} from "../../ducks/state";
import WorksCard from "../Work/Work";
import Menu from "../../components/Menu";
import Intro from "../../components/Intro/Intro";
import {TweenMax} from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
const C = CSSPlugin;

class Press extends Component {



  componentDidMount(){

    Intro.prototype.disable_intro();
    Menu.prototype.moveLogo('5vw','-15vw');
    Menu.prototype.hideMenu();



    TweenMax.set('.news_l_bg', {width: 0})
    TweenMax.set('.news_r_bg', {width: '100%',autoAlpha: 0});
    TweenMax.set('.title_bg', {height: 0})
    TweenMax.set('.title_content span', { y:50})
    TweenMax.set('.news_page', {autoAlpha: 1,delay:0.2});
    TweenMax.to('.news_l_bg',0.3, {width: '100%', ease: "Power1.easeOut"});
    TweenMax.to('.news_r_bg',0.1, {width: '100%',autoAlpha: 1, ease: "Power1.easeOut", delay:0.5});
    TweenMax.to('.title_bg',0.4, {height: '100%', ease: "Power2.easeInOut", delay:0.2});
    TweenMax.to('.title_content span',0.5, {y: 0, ease: "Power2.easeOut", delay:0.4});

  }

    render() {

      const { match } = this.props;
      let slug = ''
      if(!match.params.slug){
        slug = _.first(json_press.press).slug
      }else{
        slug = match.params.slug
      }


        return (
            <div className="news">
              <div className="news_l">
                <div className="news_l_bg"/>
                <div className="title">
                  <div className="title_bg"/>
                  <div className="title_content"><span>Revue de presse</span></div>
                </div>
                <NewsList
                  flux="presse"
                />
              </div>
              <div className="news_r">
                <div className="news_r_bg"/>
                <NewsPage
                  slug={slug}
                  flux="presse"
                />
              </div>
            </div>
        )
    }
}

export default Press

