import React, {Component} from 'react'
import {json_news, json_press, json_col} from '../../ducks/state'
import _ from 'lodash';
import {TweenMax} from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
const C = CSSPlugin;

class NewsPage extends Component {

  showPage(delay){

    TweenMax.set('.news_page',{scrollTo﻿:{y:0} });
    TweenMax.killTweensOf('.news_page_content_img_bg');
    TweenMax.set('.news_page_content', {autoAlpha: 0, y: 10});
    TweenMax.set('.news_page_title', {autoAlpha: 0, x: 20});
    TweenMax.set('.news_page_head .date', {autoAlpha: 0, x: 20});
    TweenMax.set('.news_page_content_img img', {autoAlpha: 0});
    TweenMax.to('.news_page_content',0.7, { y: 0, ease: "Power1.easeOut", delay:delay+0.1});
    TweenMax.to('.news_page_content',0.3, {autoAlpha: 1, ease: "Power1.easeOut", delay:delay+0.1});
    TweenMax.to('.news_page_title',0.5, {autoAlpha: 1, x: 0, ease: "Power1.easeOut",delay:delay});
    TweenMax.to('.news_page_head .date',0.3, {autoAlpha: 1, x: 0, ease: "Power1.easeOut", delay:delay+0.1});
    TweenMax.to('.news_page_content_img_bg',0.4, {height: '100%',top:'auto', bottom:0, ease: "Power1.easeOut", delay:delay+0.});
    TweenMax.set('.news_page_content_img img', {autoAlpha: 1, delay:delay+0.4});
    TweenMax.to('.news_page_content_img_bg',0.3, {height: '0%', bottom:'auto', top:0, ease: "Power2.easeInOut", delay:delay+0.4});
  }

  componentDidMount() {
    this.showPage(0.5);
  }


  render(){

    const {slug, flux} = this.props;
    let json = '';
    if(flux === 'actualites' ){
      json = json_news.news
    }else if(flux === 'presse')
    {
      json = json_press.press
    }else{
      json = json_col.col
    }

    const news = _.find(json, ['slug', slug])
    let date = new Date(news.date);
    let months = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"]
    let date_y = date.getFullYear();
    date = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
    if(flux === 'presse' || flux === 'collaborations'){
      date = date_y;
    }


    this.showPage(0)
    console.log(news.image);


    return (
      <div className="news_page">
        <div className="news_page_head">
          <div className="news_page_head_content">
            <div className="date">
              { date}
            </div>
            <div className="news_page_title">
              {news.title}
            </div>
          </div>
        </div>
        <div className="news_page_content">
          {news.image  ? (
          <div className="news_page_content_img">
            <div className="news_page_content_img_bg"/>
            <img src={news.image} />
          </div>
          ):(
            <div/>
            )
          }
          <div dangerouslySetInnerHTML={{__html:news.body}} />
        </div>
      </div>
    )
  }
}

export default NewsPage