import React, { Component } from 'react'
import Intro from '../../components/Intro/Intro'
import Menu from "../../components/Menu";
import { json_content } from '../../ducks/state'
import {TweenMax} from "gsap/all";
import {Link} from "react-router-dom";



class Privacy extends Component {


  componentDidMount(){
    Intro.prototype.disable_intro();
    Menu.prototype.hideMenu();
    Menu.prototype.moveLogo('35%', '-45%');

    TweenMax.set('.title_bg', {height: 0})
    TweenMax.set('.generic_close', {x: '100%'})
    TweenMax.set('.title_content span', { y:50})
    TweenMax.set('.legals_content', { y:20, autoAlpha:0})


    TweenMax.to('.title_bg',0.4, {height: '100%', ease: "Power2.easeInOut", delay:0.2});
    TweenMax.to('.title_content span',0.5, {y: 0, ease: "Power2.easeOut", delay:0.4});
    TweenMax.to('.legals_content',0.5, {y: 0, autoAlpha:1, ease: "Power2.easeOut", delay:0.6});
    TweenMax.to('.generic_close',0.2, {x: '0%',  ease: "Power2.easeOut", delay:0.8});


  }


  render() {
    return (
      <div className="legals">
        <div className="legals_title title">
          <div className="title_bg"/>
          <div className="title_content">
            <span style={{fontSize:'1.8rem'}}>Politique de confidentialité</span>
          </div>
        </div>
        <Link to="/contact" className="generic_close"><img src="./close-button.png"/></Link>
        <div className="legals_content" dangerouslySetInnerHTML={{__html:  json_content['content']['privacy']['privacy'] }}>
        </div>
      </div>
    )
  }
}

export default Privacy
