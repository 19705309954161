import React, { Component } from 'react'
import Intro from '../../components/Intro/Intro'
import Menu from "../../components/Menu";
import { json_content } from '../../ducks/state'
import {TweenMax} from "gsap/all";
import Credits from "../../components/Credits";
import {Link} from "react-router-dom";



class Contact extends Component {


  componentDidMount(){
    Intro.prototype.disable_intro();
    Menu.prototype.hideMenu();
    Menu.prototype.moveLogo('-55%', '-45%');

    TweenMax.set('.contact_img_bg', {width: 0})
    TweenMax.set('.contact_img_img', {autoAlpha: 0, x:-10})
    TweenMax.set('.contact_map_bg', {width: 0})
    TweenMax.set('.contact_map_img', {autoAlpha: 0, scale:1.03})
    TweenMax.set('.contact_bloc_content', {autoAlpha: 0, y:10})

    TweenMax.set('.title_bg', {height: 0})
    TweenMax.set('.title_content span', { y:50})

    TweenMax.to('.contact_img_bg',0.3, {width: '100%',right:'auto',left:0, ease: "Power1.easeOut"});
    TweenMax.set('.contact_img_img', {autoAlpha: 1, delay:0.3});
    TweenMax.to('.contact_img_img',0.5, {autoAlpha: 1, delay:0.3,x:0,ease: "Power1.easeInOut"});
    TweenMax.to('.contact_img_bg',0.5, {width: '0%',right:0,left:'auto',  ease: "Power1.easeIn", delay:0.3});

    TweenMax.to('.contact_map_bg',0.3, {width: '100%',right:0,left:'auto', ease: "Power2.easeOut", delay:0.3});
    TweenMax.set('.contact_map_img', {autoAlpha: 1, delay:0.6});
    TweenMax.to('.contact_map_img',0.6, {autoAlpha: 1, delay:0.6,scale:1,ease: "Power1.easeInOut"});
    TweenMax.to('.contact_map_bg',0.4, {width: '0%',right:'auto',left:0,  ease: "Power1.easeIn", delay:0.6});

    TweenMax.to('.title_bg',0.4, {height: '100%', ease: "Power2.easeInOut", delay:0.2});
    TweenMax.to('.title_content span',0.5, {y: 0, ease: "Power2.easeOut", delay:0.4});

    TweenMax.staggerTo('.contact_bloc_content',0.3,{autoAlpha: 1,y:0, ease: "Power1.easeOut", delay:0.5},0.05);




  }

  displayCredit() {
    Credits.prototype.displayCredit()
  }

  render() {
    return (
      <div className="contact">
        <div className="contact_bg"/>
        <div className="contact_img">
        <div className="contact_img_img"
         style={{
           backgroundImage: "url(./contact.png)"
         }}
        />
          <div className="contact_img_bg"/>
        </div>
        <div className="contact_title title">
          <div className="title_bg"/>
          <div className="title_content">
            <span>Contact</span>
          </div>
          <a href=" https://www.facebook.com/Françoise-NThépé-Architecture-Design-184428968838655/" target="_blank"><img src="./facebook.png"/></a>
          <a href="https://www.linkedin.com/in/francoise-nthepe-1b255526" target="_blank"><img src="./linkedin.png"/></a>
        </div>
        <div className="contact_description">
          { json_content['content']['contact']['texte'] }
        </div>
        <div className="contact_bloc contact_location">
          <div className="contact_bloc_content">
            <span>{ json_content['content']['contact']['adresse_titre'] }</span>
            <div  dangerouslySetInnerHTML={{__html: json_content['content']['contact']['adresse_contenu'] }} />
          </div>
        </div>
        <div className="contact_bloc contact_metro">
          <div className="contact_bloc_content">
          <span>{ json_content['content']['contact']['metro_titre'] }</span>
            <div  dangerouslySetInnerHTML={{__html:  json_content['content']['contact']['metro_contenu'] }} />
          </div>
        </div>
        <div className="contact_bloc contact_bus">
          <div className="contact_bloc_content">
          <span>{ json_content['content']['contact']['bus_titre'] }</span>
            <div  dangerouslySetInnerHTML={{__html:  json_content['content']['contact']['bus_contenu'] }} />
          </div>
        </div>
        <div className="contact_bloc contact_tel">
          <div className="contact_bloc_content">
          <span>{ json_content['content']['contact']['tel_titre'] }</span>
            <div  dangerouslySetInnerHTML={{__html:  json_content['content']['contact']['tel_contenu'] }} />
          </div>
        </div>
        <div className="contact_bloc contact_mail">
          <div className="contact_bloc_content">
          <span>{ json_content['content']['contact']['mail_titre'] }</span>
             <div  dangerouslySetInnerHTML={{__html:  json_content['content']['contact']['mail_contenu'] }} />
          </div>
        </div>
        <div className="contact_bloc contact_status">
          <div className="contact_bloc_content">
            <div className="credits_btn" onClick={this.displayCredit} >Crédits</div><br/>
            <Link to="/mentions-legales" className="legals_btn">Mentions légales</Link><br/>
            <Link to="/politique-confidentialite" className="legals_btn">Politique de confidentialité</Link><br/><br/>
            <div  dangerouslySetInnerHTML={{__html:  json_content['content']['contact']['status'] }} />
          </div>
        </div>
        <div className="contact_map">
          <div className="contact_map_img"
               style={{
                 backgroundImage: "url(./map.png)"
               }}
          />
          <div className="contact_map_bg"/>
        </div>
      </div>
    )
  }
}

export default Contact
