import React, {Component} from 'react'
import NewsListItem from './NewsListItem'
import { json_news, json_press, json_col } from '../../ducks/state'
import {TweenMax} from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
const C = CSSPlugin;

class NewsList extends Component {



  componentDidMount() {
    TweenMax.set('.news_list_item_bg', {width: '0%',right:'auto', left:0});
    TweenMax.set('.news_list_item .date', {autoAlpha: 0, x: 10});
    TweenMax.set('.news_list_item_title', {autoAlpha: 0, x: 10});

    TweenMax.staggerTo('.news_list_item_bg',0.4,{width: '100%',ease: "Power1.easeOut",delay:0},0.1);
    TweenMax.staggerTo('.news_list_item_bg',0.4,{width: '0%',right:0, left:'auto',ease: "Power1.easeOut",delay:0.4},0.1);
    TweenMax.staggerTo('.news_list_item .date',0, {autoAlpha: 1, delay:0.4},0.1);
    TweenMax.staggerTo('.news_list_item_title', 0,{autoAlpha: 1, delay:0.4},0.1);
    TweenMax.staggerTo('.news_list_item .date',0.4, {x: 0,autoAlpha:1, ease: "Power2.easeOut", delay:0.4},0.1);
    TweenMax.staggerTo('.news_list_item_title',0.5, {x: 0,autoAlpha:1, ease: "Power2.easeOut", delay:0.4},0.1);

  }

  render() {


    const { flux } = this.props
    let json = '';
    if(flux === 'actualites' ){
      json = json_news.news
    }else if(flux === 'presse')
    {
      json = json_press.press
    }else{
      json = json_col.col
    }

    const list_news_item = json.map((news, i) => (
        <NewsListItem
          key={news.id}
          news={news}
          flux={flux}
        />
    ))

    return (
      <div className="news_list">
        {list_news_item}
      </div>
    )
  }
}

export default NewsList
