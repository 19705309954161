import React, {Component} from 'react'
import './styles/App.scss'
import {
  Route,
  Switch
} from 'react-router-dom'
import Menu from './components/Menu'
import Intro from './components/Intro'
import Home from './pages/Home'
import About from './pages/About'
import Works from './pages/Works'
import Work from './pages/Work'
import Press from './pages/Press'
import Col from './pages/Col'
import News from './pages/News'
import Contact from './pages/Contact'
import Menu_mobile from "./components/Menu/Menu_mobile";
import TitleAndMetaTags from "./components/TitleAndMetaTags";
import Preload from "./components/Preload";
import Credits from "./components/Credits";
import Legals from "./pages/Legals";
import Privacy from "./pages/Privacy";






class App extends React.Component {


  render() {
    return (

      <div className="app">
        <TitleAndMetaTags
          title="Françoise N'THEPE - Architecture & Design - Paris"
          description="Françoise N'THEPE - Architecture & Design - Paris"
          image="/fb.jpg"
        />
        <Intro/>
        <Menu/>
        <Menu_mobile/>
        <Credits/>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/lagence" component={About}/>
          <Route strict path={`/realisations/:slug/`}
                 component={props => <Work {...props} />}/>
          <Route strict path={`/actualites/:slug/`}
                 component={News}/>
          <Route path="/actualites" component={News}/>
          <Route path="/realisations" component={Works}/>
          <Route strict path={`/presse/:slug/`}
                 component={Press}/>
          <Route path="/presse" component={Press}/>
          <Route strict path={`/collaborations/:slug/`}
                 component={Col}/>
          <Route path="/collaborations" component={Col}/>
          <Route path="/contact" component={Contact}/>
          <Route path="/mentions-legales" component={Legals}/>
          <Route path="/politique-confidentialite" component={Privacy}/>

        </Switch>
        <Preload/>
      </div>
    )
  }

}

export default App
