import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class WorksCard extends Component {

    render() {

        const { work, number } = this.props
        let name = 'work_first works_card';
        // if(number % 3 === 0){
        //   name = 'work_third';
        // }
        if(number % 2 === 0){
          name = 'work_second works_card';
        }

        if(number % 3 === 0){
          name = 'work_third works_card';
        }
        if(number % 4 === 0){
          name = 'work_fourth works_card';
        }

        return (
          <Link to={`/realisations/${work.slug}/`} className={name} >
              <div className="works_card_bg" style={{
                backgroundImage: `url(${work.image_thumb})`
          }}>
              </div>
              <div className="works_card_bg_black"></div>
              <div className="works_card_content">
                  <div className="works_card_content_title">
                    { work.title }
                  </div>
                  <div className="works_card_content_location">
                    { work.place }
                  </div>
              </div>
          </Link>
        )
    }
}

export default WorksCard