import React, { Component } from 'react'
import {TweenMax} from "gsap/all";


class Credits extends Component {

    displayCredit(){
      TweenMax.to('.credits',0.3, {autoAlpha:1,ease: "Power1.easeOut"});
    }
    closeCredit(){
      TweenMax.to('.credits',0.3, {autoAlpha:0,ease: "Power1.easeOut"});
    }

    render() {


        return (
            <div className="credits">
              <div className="credits_bg"/>
              <div className="credits_con">
                <div className="credits_close" onClick={this.closeCredit}>
                  <img src="./close-button.png"/>
                </div>
                <strong>Crédits</strong><br/><br/>
                Direction artistique : Warmgrey<br/>
                Design/Développement/Intégration : Sparkk<br/>
                Photos : M. de Groot (portraits), DR
              </div>
            </div>
        )
    }
}
export default Credits