import React, {Component, useState} from 'react'
import Swiper from 'react-id-swiper';
import {Pagination, Navigation} from 'swiper/dist/js/swiper.esm'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import _ from 'lodash';




class WorkSwiper extends Component {

  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  componentDidUpdate() {
  }

  render() {

    const { photoIndex, isOpen } = this.state;
    const params = {
      modules: [Pagination, Navigation],
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      spaceBetween: 0,
      observer: true,
      on: {
        observerUpdate: function () {
          this.slideTo(0);
        },
      }
    }

    const {gallery} = this.props;

    let images = [];
    _.forEach(gallery,function(value){
      images.push(value.image)
    });


    const gallery_swipe = gallery.map((image, i) => (
      <div
        key={i}
        style={{
          backgroundImage: `url(${image.image_crop})`
        }}
        onClick={() => this.setState({ isOpen: true })}
      />
    ))

    return (

      <div className="work_gallery">
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
        <div className="work_gallery_arrows"/>
        <div className="work_gallery_slider">
          <Swiper {...params} shouldSwiperUpdate={true}>
            {gallery_swipe}
          </Swiper>
        </div>
        <div className="work_gallery_r"/>
        <div className="work_gallery_bottom" id="work_gallery_bottom"/>
      </div>

    )
  }
}

export default WorkSwiper


