import React, { Component } from 'react'
import Logo from '../Logo'
import {TweenMax} from "gsap/all";
import Menu from '../../components/Menu/Menu'

export let display_intro = 1;

class Intro extends Component {


    disable_intro(){
        display_intro = 0;
    }


    intro() {

        if(display_intro == 1){
            TweenMax.set('.intro',{autoAlpha:1});
            TweenMax.staggerTo('.intro .logo_content span',0.8,{y: '0%',ease: "Power4.easeOut"},0.1);
            TweenMax.to('.intro .logo',1.5, {y: 0, ease: "Power1.easeOut"});
            TweenMax.staggerTo('.intro .logo_content span',0.9,{y:'-200%',ease: "Power2.easeIn", delay: 1.5},0.02);
            TweenMax.set('.intro',{autoAlpha:0,delay:3});
            Menu.prototype.display_lines(2);
            Menu.prototype.showMenuIntro(2.7);


        }else{
            Menu.prototype.showMenu();
            Menu.prototype.display_lines();

        }


    }

    componentDidMount(){

        TweenMax.set('.intro .logo_content span', {y: '200%'});
        TweenMax.set('.intro .logo', {y: 10});
    }

    render() {
        return (
            <div className="intro">
                <Logo/>
            </div>
        )
    }
}
export default Intro