import React, {Component} from 'react'
import Menu from '../../components/Menu/Menu'
import {TweenMax} from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import Intro from '../../components/Intro/Intro'
import {json_content} from '../../ducks/state'
import {Link} from "react-router-dom";

const C = CSSPlugin;

class About extends Component {


  componentDidMount() {
    TweenMax.set('.about_l', {opacity: 0, y: 10})
    TweenMax.set('.about_r', {opacity: 0, y: -10});
    TweenMax.to('.about_l', 0.9, {opacity: 1, y: 0, ease: "Power1.easeOut"});
    TweenMax.to('.about_r', 0.7, {opacity: 1, y: 0, ease: "Power1.easeOut"});
    TweenMax.set('.about_img_bg', {height: '0%'});
    TweenMax.to('.about_img_bg', 0.2, {height: '100%', top: 'auto', bottom: 0, ease: "Power1.easeOut"});
    TweenMax.set('.about_img_img', {autoAlpha: 1, delay: 0.2, y: 20});
    TweenMax.to('.about_img_img', 0.8, {y: 0, ease: "Power1.easeOut", delay: 0.2});
    TweenMax.to('.about_img_bg', 0.6, {height: '0%', top: 0, bottom: 'auto', ease: "Power1.easeInOut", delay: 0.2});


    //Menu.prototype.moveLogo('5vw', '-15vw');
    Intro.prototype.disable_intro();
    Menu.prototype.hideMenu();
  }

  render() {
    return (
      <div className="about">

        <div className="about_l">
          <div className="about_l_bg"/>
          <div className="about_l_content">
            <div className="about_img">
              <div className="about_img_bg"/>
              <div className="about_img_img"
                   style={{
                     backgroundImage: `url(${json_content['content']['about']['image_1']})`
                   }}
              />

            </div>

            <div className="about_bloc about_bloc_black">
              <div dangerouslySetInnerHTML={{__html: json_content['content']['about']['dates']}}/>
            </div>
            <div className="about_quote">
              <div className="about_quote_l">
                {json_content['content']['about']['citation']}
              </div>
            </div>
            <div className="about_bloc">
              <div dangerouslySetInnerHTML={{__html: json_content['content']['about']['text_1']}}/>
            </div>
          </div>
        </div>
        <div className="about_r">
          <div className="about_r_bg"></div>
          <div className="about_r_content">
            <div className="about_top">
              <div className="logo">
                <div className="logo_lastname">
                  N’thépé
                </div>
              </div>
            </div>
            <div className="about_bloc">
              <div dangerouslySetInnerHTML={{__html: json_content['content']['about']['text_2']}}/>
            </div>
            <div className="about_bloc about_titre">
              {json_content['content']['about']['titre']}
            </div>
            <div className="about_bloc">
              <div dangerouslySetInnerHTML={{__html: json_content['content']['about']['text_3']}}/>
            </div>
          </div>
        </div>
        <div className="about_l">
          <div className="about_l_bg"></div>
          <div className="about_l_content">
            <div className="about_img_bottom" style={{
              backgroundImage: `url(${json_content['content']['about']['image_2']})`
            }}>
            </div>
          </div>
        </div>
        <div className="about_r about_grid">
          <div className="about_r_bg"></div>
          <div className="logo">
            <div className="logo_baseline_top">
              Architecture
            </div>
          </div>
        </div>
        <div className="about_l about_grid">
          <div className="about_l_bg"/>
          <div className="logo">
            <div className="logo_baseline_bottom">
              & design
            </div>
          </div>
        </div>
        <Link to="/contact" >
        <div className="about_r">
          <div className="about_contact">
            <span>Contactez nous</span>
          </div>
        </div>
        </Link>
      </div>

    )
  }
}

export default About
