import React, { Component } from 'react'
import Intro from '../../components/Intro/Intro'



class Home extends Component {


  componentDidMount(){
      Intro.prototype.intro();
  }


  render() {


    return (<div/>)
  }
}

export default Home
